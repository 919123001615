import React from 'react';
import { Link } from "gatsby";

import DiscoverImg1 from "../../assets/images/bigdata-analytics/HomeFour.png";
import DiscoverImg2 from "../../assets/images/bigdata-analytics/HomeThree.png";
import AnalyticsShape1 from "../../assets/images/bigdata-analytics/analytics-shape1.png";

const DiscoverArea = () => {
    return (
        <div className="discover-area ptb-80">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="discover-image">
                            <img src={DiscoverImg1} alt="image" />
                            <img src={DiscoverImg2} alt="image" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        {/* <div className="discover-content"> */}
                            {/* <h2>Why Arambh</h2> */}

                            <div className="discover-content h2">
                            <ul>
                                <h2><li>India’s fastest growing Home Loan company</li>
                                <li>Founded by IITan and multinational professionals</li>
                                <li>Over 50 years of combined experience</li>
                                <li>Loan Advisors trained to offer neutral advice that benefits you</li>
                                <li>100% automated pre-qualification to loan disbursal process</li>
                                <li>Lowest EMI offers from 100+ Housing Finance Companies</li>
                                <li>Super fast loan disbursal in less than a week</li>
                                <li>Automatic Balance Transfer : Switch to lower EMI</li>
                                </h2>
                            </ul>
                            </div>
                            {/* <p>Arambh was founded by IIT’an & multinational professionals, who were friends first and over 50 years of combined international experience in creating technology, digital & media businesses.</p> */}
                            {/* <p>Having themselves purchase & sold properties, they knew the challenges, long delays, opaque system, lack of transparency, and the overall slow twentieth century processes. They decide to do something about it, and Arambh was Born!</p> */}
{/* 
                            <Link to="/aboutus" className="btn btn-primary">
                                Discover More
                            </Link> */}
                       
                    </div>
                </div>
            </div>

            <div className="analytics-shape1">
                <img src={AnalyticsShape1} alt="image" />
            </div>
        </div>
    )
}

export default DiscoverArea;  