import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/NavbarStyleFour";
import Form from '../components/BigdataAnalytics/Form';
import Features from '../components/BigdataAnalytics/Features'
import DiscoverArea from '../components/BigdataAnalytics/DiscoverArea';
import Feedback from '../components/BigdataAnalytics/Feedback';
import Partners from '../components/BigdataAnalytics/Partners'

const IndexPage = () => (
    <Layout>
        <SEO title="Best Home Loan service - Arambh" />
        <Navbar />
        <Form />
        <Features/>
        <Partners/>
        <DiscoverArea />
        <Feedback />

    </Layout>
)

export default IndexPage;
