import React from 'react';

import PartnerImg19 from '../../assets/images/partner-img/axislogo.png'
import PartnerImg20 from '../../assets/images/partner-img/SBIwidelogo.png'
import PartnerImg21 from '../../assets/images/partner-img/hdfc-3.png'
import PartnerImg22 from '../../assets/images/partner-img/idfclogo.png'
import PartnerImg23 from '../../assets/images/partner-img/kotak.jpg'
import PartnerImg24 from '../../assets/images/partner-img/liclogo.png'
import PartnerImg25 from '../../assets/images/partner-img/Yes-15.png'
import PartnerImg26 from '../../assets/images/partner-img/Yes-16.png'
import PartnerImg27 from '../../assets/images/partner-img/Yes-17.png'
import PartnerImg28 from '../../assets/images/partner-img/Yes-18.png'
import PartnerImg29 from '../../assets/images/partner-img/pnb.png'
import PartnerImg30 from '../../assets/images/partner-img/Godrej.png'
import PartnerImg31 from '../../assets/images/partner-img/dhfl.png'








import Loadable from '@loadable/component';
const OwlCarousel = Loadable(() => import('react-owl-carousel3'));

const options = {
    loop: false,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    autoplayTimeout: 5000,
    responsive: {
        0:{
            items:2,
        },
        768:{
            items:4,
        },
        1200:{
            items:6,
        }
    }
}

const Partners = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <div className="repair-partner-area ptb-80 bg-f9fafb">
            <div className="container">
            <div className="section-title">
                    {/* <h2>Associated Banks</h2> */}
					{/* <div className="bar"></div> */}
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                </div>
                {display ? <OwlCarousel 
                    className="repair-partner-slides owl-carousel owl-theme"
                    {...options}
                >
                    <div className="single-repair-partner">
                        <a href="https://themeforest.net/user/envytheme/portfolio" target="_blank" rel="noreferrer">
                            <img src={PartnerImg19} alt="image" />
                            <img src={PartnerImg19} alt="image" />
                        </a>
                    </div>
            
                    <div className="single-repair-partner">
                        <a href="https://themeforest.net/user/envytheme/portfolio" target="_blank" rel="noreferrer">
                            <img src={PartnerImg20} alt="image" />
                            <img src={PartnerImg20} alt="image" />
                        </a>
                    </div>
                
                    <div className="single-repair-partner">
                        <a href="https://themeforest.net/user/envytheme/portfolio" target="_blank" rel="noreferrer">
                            <img src={PartnerImg21} alt="image" />
                            <img src={PartnerImg21} alt="image" />
                        </a>
                    </div>
        
                    <div className="single-repair-partner">
                        <a href="https://themeforest.net/user/envytheme/portfolio" target="_blank" rel="noreferrer">
                            <img src={PartnerImg22} alt="image" />
                            <img src={PartnerImg22} alt="image" />
                        </a>
                    </div>
            
                    <div className="single-repair-partner">
                        <a href="https://themeforest.net/user/envytheme/portfolio" target="_blank" rel="noreferrer">
                            <img src={PartnerImg23} alt="image" />
                            <img src={PartnerImg23} alt="image" />
                        </a>
                    </div>
        
                    <div className="single-repair-partner">
                        <a href="https://themeforest.net/user/envytheme/portfolio" target="_blank" rel="noreferrer">
                            <img src={PartnerImg24} alt="image" />
                            <img src={PartnerImg24} alt="image" />
                        </a>
                    </div>

                    <div className="single-repair-partner">
                        <a href="https://themeforest.net/user/envytheme/portfolio" target="_blank" rel="noreferrer">
                            <img src={PartnerImg25} alt="image" />
                            <img src={PartnerImg25} alt="image" />
                        </a>
                    </div>

                    <div className="single-repair-partner">
                        <a href="https://themeforest.net/user/envytheme/portfolio" target="_blank" rel="noreferrer">
                            <img src={PartnerImg26} alt="image" />
                            <img src={PartnerImg26} alt="image" />
                        </a>
                    </div>

                    <div className="single-repair-partner">
                        <a href="https://themeforest.net/user/envytheme/portfolio" target="_blank" rel="noreferrer">
                            <img src={PartnerImg27} alt="image" />
                            <img src={PartnerImg27} alt="image" />
                        </a>
                    </div>

                    <div className="single-repair-partner">
                        <a href="https://themeforest.net/user/envytheme/portfolio" target="_blank" rel="noreferrer">
                            <img src={PartnerImg28} alt="image" />
                            <img src={PartnerImg28} alt="image" />
                        </a>
                    </div>

                    <div className="single-repair-partner">
                        <a href="https://themeforest.net/user/envytheme/portfolio" target="_blank" rel="noreferrer">
                            <img src={PartnerImg29} alt="image" />
                            <img src={PartnerImg29} alt="image" />
                        </a>
                    </div>

                    <div className="single-repair-partner">
                        <a href="https://themeforest.net/user/envytheme/portfolio" target="_blank" rel="noreferrer">
                            <img src={PartnerImg30} alt="image" />
                            <img src={PartnerImg30} alt="image" />
                        </a>
                    </div>

                    <div className="single-repair-partner">
                        <a href="https://themeforest.net/user/envytheme/portfolio" target="_blank" rel="noreferrer">
                            <img src={PartnerImg31} alt="image" />
                            <img src={PartnerImg31} alt="image" />
                        </a>
                    </div>

        
        
                </OwlCarousel> : ''}
            </div>
        </div>
    );
}

export default Partners;
