import React from 'react';
import { Link } from "gatsby";
import * as Icon from 'react-feather';

const OurServices = () => {
    return (
        <div className="services-area-two pt-40 pb-50 bg-f7fafd">
			<div className="container">
				<div className="section-title">
					{/* <h2>We add Values</h2> */}
					{/* <div className="bar"></div> */}
					{/* <p>It's not just about Home loans, we enable Hassle free Home owning experience.</p> */}
				</div>

				<div className="row justify-content-center">
					<div className="col-lg-4 col-md-6">
						<div className="single-services-box">
							<div className="icon">
                                <Icon.Airplay />
							</div>
							<h3>
								<Link to="/service-details">
                                Honest Advice
								</Link>
                            </h3>
							<p>Personalized Strategy to recommend best bank, the loan amount & Tenure for you.</p>
						</div>
					</div>

					<div className="col-lg-4 col-md-6">
						<div className="single-services-box">
							<div className="icon">
                                <Icon.DollarSign />
							</div> 
							<h3>
								<Link to="/service-details">
                                	Free Escrow Bank Account
								</Link>
                            </h3>
							<p>Keep Advance Token money Safe till property docs 
                                verified and loan approved
                            </p>
						</div>
					</div>

					<div className="col-lg-4 col-md-6">
						<div className="single-services-box">
							<div className="icon">
                                <Icon.BarChart />
							</div> 
							<h3>
								<Link to="/service-details">
								Free Chartered Accountant
								</Link>
                            </h3>
							<p>Recieve professional guidance on income tax, property tax, stamp duty and more.</p>
						</div>
					</div>

					<div className="col-lg-4 col-md-6">
						<div className="single-services-box">
							<div className="icon bg-c679e3">
                                <Icon.Anchor />
							</div>
							<h3>
								<Link to="/service-details">
								Free Legal Consultation
								</Link>
                            </h3>
							<p>Understand legal viewpoint & get property documents verified by the best legal mind.</p>
						</div>
					</div>

					<div className="col-lg-4 col-md-6">
						<div className="single-services-box">
							<div className="icon bg-c679e3">
                                <Icon.Home />
							</div> 
							<h3>
								<Link to="/service-details">
								Property Registration
								</Link>
                            </h3>
							<p>Undertake full legal process, register sale deed, stamp duty payment & registration.</p>
						</div>
					</div>

					<div className="col-lg-4 col-md-6">
						<div className="single-services-box">
							<div className="icon bg-c679e3">
                                <Icon.Move />
							</div> 
							<h3>
								<Link to="/service-details">
                                Balance Transfer
								</Link>
                            </h3>
							<p>Personal recommendation every 6 months to reduce your EMI by switching the bank.</p>
						</div>
					</div>

					{/* <div className="col-lg-4 col-md-6">
						<div className="single-services-box">
							<div className="icon bg-eb6b3d">
                                <Icon.MousePointer />
							</div>
							<h3>
								<Link to="/service-details">
                                	Drag & Drop Functionality
								</Link>
                            </h3>
							<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
						</div>
					</div> */}

					{/* <div className="col-lg-4 col-md-6">
						<div className="single-services-box">
							<div className="icon bg-eb6b3d">
                                <Icon.Bell />
							</div> 
							<h3>
								<Link to="/service-details">
                                	Deadline Reminders
								</Link>
                            </h3>
							<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
						</div>
					</div> */}

					{/* <div className="col-lg-4 col-md-6">
						<div className="single-services-box">
							<div className="icon bg-eb6b3d">
                                <Icon.Send />
							</div> 
							<h3>
								<Link to="/service-details">
                                	Modern Keyword Analysis
								</Link>
                            </h3>
							<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
						</div>
					</div> */}
				</div>
			</div>
		</div>
    )
}

export default OurServices;  