import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { useState } from 'react';

function FormSection() {
  const [state, handleSubmit] = useForm("mpznwqwy");
  const [visible, setVisible] = useState(false);

  const showText = () => {
    setVisible(true);
    };


  if (state.succeeded) {
      return <div className="col-lg-5 offset-lg-1">
								<div className="banner-form ml-3">
                                    <form>
										<h2 className="form-submit">Thanks for your enquiry,
                                            We will call you back Shortly</h2>
                                            <div className="btn-others">
                                            <a href="http://arambh.co" target="_blank">Explore More</a>
                                            </div>

                                            {/* <button >Explore More</button> */}

									
									</form>
                                </div>
                            </div>

  }
  return (
		<div className="col-lg-5 offset-lg-1">
								<div className="banner-form ml-3">
									<form onSubmit={handleSubmit}>
										<h2 className="form-text">Please enter details and request callback</h2>
										<div className="form-group">
											{/* <label>name</label> */}
											<input type="text" className="form-control" placeholder="Your Name" name="name"/>
										</div>

										<div className="form-group">
											{/* <label>Email</label> */}
											<input type="email" className="form-control" placeholder="Email Address" name="email" />
										</div>

										<div className="form-group">
											{/* <label>Password</label> */}
											<input type="text" className="form-control" placeholder="Phone number" name="phone" />
										</div>

										<div  className="form-group" name="options">
											<select className="form-control" name="options">
												<option> Home Loan</option>
												<option> Construction Loan</option>
												<option> Balance Transfer Loan</option>
												<option> Loan against property </option>

											</select>
										
										</div>
										<div className="form-group">
											{/* <label>Password</label> */}
											<input type="text" className="form-control" placeholder="Your Query/Comments" name ="comments"/>
										</div>
										
                                        <ValidationError 
                                         prefix="Message" 
                                         field="message"
                                        errors={state.errors}
                                         />
										
								<button type="submit" onClick={showText}  className="btn btn-primary" value="Send">Request callback</button>
									</form>
								</div>
							</div>


        )
    }
    
    export default FormSection;  