import React from 'react'; 


const Feedback = () => {
    return (
        <div className="bigdata-feedback-area pt-80 pb-50">
            <div className="container">
                <div className="section-title">
                    {/* <h2>Client Testimonials</h2> */}
                    {/* <div className="bar"></div> */}
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                </div>
                
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-feedback-box">
                            <div className="feedback-desc">
                                <p>Applause to Naman and the team in Arambh. I took a home loan from SBI through them in july 2021.
                                    During that time I approached a lot of banks and majority of them offered upto 35Lakhs, While my requirements
                                    was 70Lakhs. But Arambh helped me to get 65Lakhs through SBI. Thanks to Arambh, I was able to buy my dream home.
                                </p>
                            </div>

                            <div className="client-info">
                                {/* <img src={Author1} alt="image" /> */}
                                <h3>Arvind Singh Jamwal, Air India</h3>
                                <p>Mr.Jamwal was in rented space, and got help from Arambh to buy a flat in Godrej Oasis, Sector 88A, Gurgaon</p>
                                {/* <span>Founder & CEO</span> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-feedback-box">
                            <div className="feedback-desc">
                                <p>They are very transparent compared to three companies we have worked with before. Each step is communicated 
                                    nicely by Mr.Naman Jain and they have a practice which others also should learn - Their loan advisors call the customers
                                    each day, even if there is not much movement. The team was very straighforward, honest and used to answer our queries
                                </p>
                            </div>

                            <div className="client-info">
                                {/* <img src={Author2} alt="image" /> */}
                                <h3>Mr.Sanjeev Sharma, Concentrix</h3>
                                <p>Mr.Sharma had purchased a 3BHK flat in Ardee City, Gurgaon, with the help of loan through PNB housing finance.</p>

                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-feedback-box">
                            <div className="feedback-desc">
                                <p>Surprisingly Arambh was better than the others for us. Firstly the team kept us up to date on most things. Secondly, 
                                    their website was easy to use. The rate of interest was a surprise - I never knew other banks could offer lower EMI than HDFC. 
                                    Mr.Naman was very helpful and we appreciated all that he did to make our loan simple and fast. Thanks and best wishes to Arambh.
                                </p>
                            </div>

                            <div className="client-info"> 
                                {/* <img src={Author3} alt="image" /> */}
                                <h3>Mr.Ramesh Kumar Sharma, State Council of Education Research & Training</h3>
                                <p>Mr. Sharma had purchased a flat in ROF, Sector 108, Gurgaon through a loan from HDFC</p>

                                {/* <span>Designer</span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feedback;  